import { ICompany, IService, IPackage, IDetail, IUser } from '@/data-types';
import { HTTP } from '@/utils/http-common';

export class Company implements ICompany {
  // @ts-ignore: Czlowieku
  public name: string;
  // @ts-ignore: Nie
  public website: string;
  // @ts-ignore: mow
  public phone: string;
  // @ts-ignore: mi
  public industry: string;
  // @ts-ignore: jak
  public users: IUser[];
  // @ts-ignore: mam
  public details: IDetail[];
  // @ts-ignore: programowac
  public packages: IPackage[];
  // @ts-ignore: kurla!
  public services: IService[];
  public dataLoaded = false;

  constructor(public id: number) {}

  public loadData() {
    HTTP.get(`api/company/${this.id}`).then((response) => {
      Object.assign(this, response.data.data);
      this.dataLoaded = true;
    });
  }

  public getKey(keyName: string) {
    if (this.hasOwnProperty(keyName)) {
      // @ts-ignore
      return this[keyName];
    }
  }
}
