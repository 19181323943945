var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.company.dataLoaded)?_c('div',{staticClass:"loadingScreen"},[_vm._v(" Ładowanie detali firmy… ")]):_vm._e(),(_vm.company.dataLoaded)?_c('div',{staticClass:"container"},[_c('section',{staticClass:"row",staticStyle:{"margin-top":"35px"}},[_c('div',{staticClass:"small-12 medium-6 large-3 columns"},[(_vm.company.getKey('logo'))?_c('img',{attrs:{"src":_vm.company.getKey('logo'),"alt":_vm.company.getKey('name')}}):_vm._e()]),_c('div',{staticClass:"small-12 medium-6 large-9 columns"},[_c('h2',[_vm._v(" "+_vm._s(_vm.company.getKey("name"))+" "),(_vm.company.confirmed)?_c('span',{staticStyle:{"color":"$primary-color"}},[_vm._v("✔")]):_vm._e()]),_c('main-btn',{staticClass:"margin-bt-1",staticStyle:{"display":"inline-block","margin-right":"10px"},attrs:{"content":'Podejrzyj firmę',"gray":true,"small":true},on:{"click":function($event){return _vm.activatePreview()}}}),_c('main-btn',{staticClass:"margin-bt-1",staticStyle:{"display":"inline-block"},attrs:{"content":'Zobacz wizualizacje',"gray":true,"small":true},on:{"click":function($event){return _vm.company.loadVisualization()}}}),(_vm.company.editionVisualisation.length)?_c('div',{staticClass:"margin-both-3"},[_c('img',{attrs:{"src":_vm.company.editionVisualisation}})]):_vm._e()],1)]),_c('section',{staticClass:"row",staticStyle:{"margin-top":"35px"}},[_c('div',{staticClass:"small-12 medium-6 large-4 columns"},[_c('h2',[_vm._v("Informacje")]),_c('ul',{staticClass:"none-list"},[_c('li',[_c('strong',[_vm._v("Id:")]),_c('br'),_vm._v(" "+_vm._s(_vm.company.id)+" ")]),_vm._l((_vm.editableKeys),function(item){return _c('li',{key:item.key},[_c('strong',[_vm._v(_vm._s(item.label)+":")]),(!item.active)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.displayValueForItem(item))+" ")]),_c('span',{staticClass:"edit",on:{"click":function($event){return _vm.setKeyActive(item)}}},[_vm._v("[edytuj]")])]):_vm._e(),(item.active)?_c('div',[(
                  item.key === 'guardian' &&
                  _vm.guardiansList !== undefined &&
                  _vm.guardiansList.length > 0
                )?_c('v-select',{attrs:{"label":"Opiekun","name":item.label,"range":_vm.guardiansList,"required":true},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.key !== 'guardian')?_c('v-input',{attrs:{"name":item.label,"type":'text',"defaultValue":_vm.company.getKey(item.key)},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),_c('span',{staticClass:"save",on:{"click":function($event){return _vm.company.saveEditionDetail(item)}}},[_vm._v("[zapisz]")])],1):_vm._e()])})],2)]),_c('div',{staticClass:"small-12 medium-6 large-4 columns"},[_c('h2',[_vm._v("Zatwierdzone sekcje")]),_c('ul',{staticClass:"sections-list"},_vm._l((_vm.company.editionSections),function(item){return _c('li',{key:item.name,staticClass:"toggle",class:{
              active: _vm.company.isConfirmedSection(item) && !item.inactive,
              busy: item.busy,
              disabled: _vm.company.isDisabledSection(item),
            },on:{"click":function($event){return _vm.company.unconfirmSection(item)}}},[_c('span',{staticClass:"icon"}),_vm._v(" "+_vm._s(item.label)+" ")])}),0),(!_vm.company.confirmed)?_c('main-btn',{staticClass:"margin-bt-1",attrs:{"content":'Oznacz jako gotową',"gray":true,"small":true},on:{"click":function($event){return _vm.company.confirmEdition()}}}):_vm._e(),(_vm.company.confirmed)?_c('main-btn',{staticClass:"margin-bt-1",attrs:{"content":'Oznacz jako nie gotową',"gray":true,"small":true},on:{"click":function($event){return _vm.company.unconfirmEdition()}}}):_vm._e()],1),(_vm.allServices)?_c('div',{staticClass:"small-12 medium-6 large-4 columns"},[_c('h2',[_vm._v("Usługi")]),_c('ul',{staticClass:"services-list"},_vm._l((_vm.allServices),function(item){return _c('li',{key:item.name,staticClass:"toggle",class:{ active: _vm.isActiveService(item.id), busy: item.busy },on:{"click":function($event){return _vm.toggleService(item.id)}}},[_c('span',{staticClass:"icon"}),_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e()]),(_vm.showDetails)?_c('section',{staticClass:"row"},[_c('div',{staticClass:"small-12 columns"},[_c('h2',[_vm._v("Szczegóły firmy")]),_c('table-data',{staticClass:"container__table-data",attrs:{"headers":['Id', 'Nazwa', 'Wartość', 'Zatwierdzone'],"tableData":_vm.company.editionDetails,"adminCategory":'detail',"searchItem":'key'},on:{"start-editing":function($event){return _vm.startEditing($event)}}})],1)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }