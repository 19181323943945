import { IPackage, IDetail } from '@/data-types';
import { HTTP } from '@/utils/http-common';
import { Company } from './Company';
import { AxiosResponse } from 'axios';
import Vue from 'vue';

export class CompanyEdition extends Company {
  public editionPackage?: IPackage;
  public editionDetails: IDetail[] = [];
  public editionServicesIds: number[] = [];
  public editionVisualisation: string = '';
  public confirmed = false;
  public editionSections = [
    {
      label: 'Dane wystawcy' ,
      code: 'ed',
      inactive: false,
    },
    {
      label: 'Stoisko wystawcy' ,
      code: 'eb',
      inactive: false,
    },
    {
      label: 'Zbieranie aplikacji' ,
      code: 'er',
      inactive: false,
    },
    {
      label: 'Wizytówka w Wirtualnym JOBICONIE' ,
      code: 'bc',
      inactive: false,
    },
    {
      label: 'Moje ogłoszenia w Wirtualnym JOBICONIE' ,
      code: 'vj',
      inactive: false,
    },
    {
      label: 'Ogłoszenia promocyjne' ,
      code: 'pa',
      inactive: false,
    },
    {
      label: 'Prelekcja' ,
      code: 'lt',
      inactive: false,
    },
  ];

  constructor(
    id: number,
    public editionId: number) {
      super(id);
      this.editionId = editionId;
  }

  public loadData() {
    HTTP.get(`api/company/${this.id}`).then((response) => {
      this.assingNewData(response);
    });
  }

  public getKey(keyName: string) {
    const editionKey = this.editionDetails.find((d) => d.plainKey === keyName);
    if (editionKey && editionKey.value) {
      return editionKey.value;
    }
    if (this.hasOwnProperty(keyName)) {
      // @ts-ignore
      return this[keyName];
    }
  }

  public saveEditionDetail(key: any) {
    if (key.key === 'package') {
      this.setPackage(key);
      return;
    }
    this.postDetail('ed_' + key.key, key.value, 1, () => {
      Vue.set(key, 'active', false);
      Vue.set(key, 'value', key.value);
    });
  }

  public addService(service: any) {
    setTimeout(() => {
      HTTP.post(`api/company/service/${this.id}/${service.id}/${this.editionId}`).then(((response) => {
        this.assingNewData(response);
        service.busy = false;
      }));
    }, 700);
  }

  public removeService(service: any) {
    HTTP.delete(`api/company/service/${this.id}/${service.id}/${this.editionId}`).then(((response) => {
      this.assingNewData(response);
      service.busy = false;
    }));
  }

  public loadVisualization() {
    if (this.editionVisualisation) {
      return;
    }

    HTTP.get(`api/company/visualisation/${this.id}/${this.editionId}`)
    .then((response) => {
      Vue.set(this, 'editionVisualisation', response.data.data.message);
    })
    .catch((e) => {
      console.error(e);
    });
  }

  public getEditionDetails() {
    return this.editionPackage;
  }

  // sekcje, mozna kiedys przerzucic do nowej klasy
  public isConfirmedSection(section: any) {
    const editionKey = this.editionDetails.find((d) => {
      return d.plainKey === 'disabled' && d.keyGroup === section.code;
    });

    return editionKey && editionKey.value && editionKey.value !== 'false';
  }

  public isDisabledSection(section: any) {
    // dla pakietu classic nie ma mozliwosci dodania ogloszenia
    // if (section.code === 'vj' && this.editionPackage && this.editionPackage.name === 'Classic') {
    //   return true;
    // }

    // jesli wykupiona wizytowka premium
    if (this.editionServicesIds.indexOf(4) !== -1) {
      if (section.code === 'bcclassic') {
        return true;
      }
    } else {
      if (section.code === 'bcpremium') {
        return true;
      }
    }

    // niewykupione prelekcje
    if (section.code === 'lt' && this.editionServicesIds.indexOf(2) === -1) {
      return true;
    }

  }

  public unconfirmSection(section: any) {
    Vue.set(section, 'busy', true);
    this.postDetail(section.code + '_disabled', 'false', 0, () => {
      Vue.set(section, 'busy', false);
      Vue.set(section, 'inactive', true);
    });
  }

  public confirmEdition() {
    if (this.confirmed) {
      return;
    }
    HTTP.post(
      `api/company/confirmed/${this.id}/${this.editionId}`,
      {
        company: this.id,
        edition: this.editionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => {
        if (this.editionPackage) {
          Vue.set(this, 'confirmed', true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  public unconfirmEdition() {
    if (!this.confirmed) {
      return;
    }

    HTTP.delete(
      `api/company/confirmed/${this.id}/${this.editionId}`,
      {
        data: {
          company: this.id,
          edition: this.editionId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => {
        if (this.editionPackage) {
          Vue.set(this, 'confirmed', false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  private setPackage(key: any): void {
    HTTP.post(`api/company/package/${this.id}/${key.value}/${this.editionId}`).then((response) => {
      Vue.set(key, 'active', false);
      Vue.set(key, 'value', key.value);
      this.loadData();
    });
  }

  private postDetail(key: string, value: string, confirmed = 1, cb?: any) {
    const sendData = new FormData();
    sendData.append('key', key);
    sendData.append('value', value);
    // @ts-ignore: Takie mamy api, i co mi pan zrobisz!?
    sendData.append('confirmed', confirmed);

    HTTP.post(`api/detail/${this.editionId}/${this.id}`, sendData)
    .then((response) => {
      cb();
    });
  }

  private assingNewData(response: AxiosResponse) {
    Object.assign(this, response.data.data);

    this.editionPackage = this.packages.find((p) => p.edition_id === this.editionId );
    if (this.editionPackage) {
      this.confirmed = this.editionPackage.confirmed === '1';
    }
    this.details.forEach((d) => {
      if (parseInt(d.key, 10) === this.editionId) {
        const keySplit = d.key.split('_');

        const editionId = keySplit.shift();
        const pageKey = keySplit.shift();
        const key = keySplit.join('_');

        d.keyGroup = pageKey;
        d.plainKey = key;

        this.editionDetails.push(d);
      }
    });

    this.editionServicesIds = [];
    this.services.forEach((x) => {
      if (x.edition_id === this.editionId) {
        const index = this.editionServicesIds.indexOf(x.id);
        if (index  === -1) {
          this.editionServicesIds.push(x.id);
        }
      }
    });

    this.dataLoaded = true;
  }
}
