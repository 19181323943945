
import Vue from "vue";
import { IUser, IService } from "@/data-types";
import { HTTP } from "@/utils/http-common";
import { CompanyEdition } from "@/models/CompanyEdition";
import { AxiosResponse } from "axios";

export default Vue.extend({
  data(): any {
    return {
      showDetails: false,
      company: undefined,
      allServices: undefined,
      guardiansList: undefined,
      editableKeys: [
        {
          key: "booth_number",
          label: "Numer stoiska",
        },
        {
          key: "guardian",
          label: "Opiekun firmy",
        },
      ],
    };
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    editionId: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.company = new CompanyEdition(this.companyId, this.editionId);
    this.company.loadData();

    this.loadAllServices();
    this.getGuardiansList();
  },
  methods: {
    loadAllServices() {
      HTTP.get(`api/services`)
        .then((response) => {
          this.allServices = response.data.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    isActiveService(id: number): boolean {
      return this.company.editionServicesIds.indexOf(id) !== -1;
    },
    toggleService(id: number) {
      const service = this.allServices.find((x: IService) => x.id === id);
      if (service.busy) {
        return;
      }

      Vue.set(service, "busy", true);
      if (this.isActiveService(id)) {
        this.company.removeService(service);
      } else {
        this.company.addService(service);
      }
    },
    activatePreview() {
      this.$store
        .dispatch("getCompanyData", this.companyId)
        .then((response: AxiosResponse) => {
          this.$router.push("/informacje-organizacyjne");
        });
    },
    setKeyActive(key: any) {
      key.value = this.company.getKey(key.key);
      Vue.set(key, "active", true);
    },
    displayValueForItem(item: any) {
      switch (item.key) {
        case "package":
          return this.company.editionPackage.name || "brak";
          break;
        case "guardian":
          const value = this.company.getKey(item.key);
          const guardianDetails = this.guardiansList?.find(
            (guardian: any) => guardian.value === parseInt(value, 10)
          );
          return guardianDetails ? guardianDetails.name : "brak";
          break;
        default:
          return item.value || this.company.getKey(item.key) || "brak";
          break;
      }
    },
    getGuardiansList() {
      HTTP.get("api/account-managers")
        .then((response: AxiosResponse) => {
          const admins = response.data;

          this.guardiansList = admins.map((user: IUser) => {
            return {
              name: user.email,
              value: user.id,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
});
